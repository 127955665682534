import { Component } from '@angular/core';
@Component({
  selector: 'gea-id-workspace-help-guide',
  templateUrl: './help-guide.component.html',
  styleUrl: './help-guide.component.scss',
})
export class HelpGuideComponent {
  isShowContactForm = false;

  showContactForm(): void {
    this.isShowContactForm = true;
  }

  closeContactForm() {
    this.isShowContactForm = false;
  }
}
