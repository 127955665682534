<geaui-offline-header headerTitle="Portal"></geaui-offline-header>
<div class="help-guide-container">
  <div class="headline">{{ 'HELP-GUIDE.TITLE' | translate }}</div>
  <div class="accordion-container">
    <p-accordion>
      <p-accordionTab header="{{ 'HELP-GUIDE.FORGOT-PASSWORD.TITLE' | translate }}">
        <p>{{ 'HELP-GUIDE.FORGOT-PASSWORD.TEXT' | translate }}</p>
      </p-accordionTab>
      <p-accordionTab header="{{ 'HELP-GUIDE.PASSWORD-NOT-WORKING.TITLE' | translate }}">
        <p>{{ 'HELP-GUIDE.PASSWORD-NOT-WORKING.TEXT' | translate }}</p>
      </p-accordionTab>
      <p-accordionTab header="{{ 'HELP-GUIDE.NO-ACCOUNT-NEED-ASSISTANCE.TITLE' | translate }}">
        <p>{{ 'HELP-GUIDE.NO-ACCOUNT-NEED-ASSISTANCE.TEXT' | translate }}</p>
      </p-accordionTab>
      <p-accordionTab header="{{ 'HELP-GUIDE.CAN-NOT-CREATE-ACCOUNT.TITLE' | translate }}">
        <p>{{ 'HELP-GUIDE.CAN-NOT-CREATE-ACCOUNT.TEXT' | translate }}</p>
      </p-accordionTab>
      <p-accordionTab header="{{ 'HELP-GUIDE.ACCOUNT-ON-HOLD.TITLE' | translate }}">
        <p>{{ 'HELP-GUIDE.ACCOUNT-ON-HOLD.TEXT' | translate }}</p>
      </p-accordionTab>
      <p-accordionTab header="{{ 'HELP-GUIDE.VERIFICATION-MAIL-NOT-RECEIVED.TITLE' | translate }}">
        <p>{{ 'HELP-GUIDE.VERIFICATION-MAIL-NOT-RECEIVED.TEXT' | translate }}</p>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="contact-gea-container">
    <div class="infotext">
      <span>{{ 'HELP-GUIDE.CONTACT-GEA.TEXT' | translate }}</span>
      <span>{{ 'HELP-GUIDE.CONTACT-GEA.SUBTEXT' | translate }}</span>
    </div>
    <geaui-button-v2 (click)="showContactForm()" preIcon="16px_chat">{{
      'HELP-GUIDE.CONTACT-GEA.BUTTON' | translate
    }}</geaui-button-v2>
  </div>
  <div class="side-modal-container">
    <geaui-contact-form-side-modal
      [title]="'Contact'"
      [show]="isShowContactForm"
      [showEmailInput]="true"
      (closeContactFormSideModal)="closeContactForm()"></geaui-contact-form-side-modal>
  </div>
</div>
