import { ColumnDefinition, ColumnRendererComponent, Membership, TranslationRendererComponent } from '@gea/digital-ui-lib';
import { EditRendererComponent } from '@gea-id/shared';
import { MembershipStateRendererComponent } from '@gea-id/shared';
import { MembershipStateRendererConfig } from '@gea-id/shared';

export const MEMBERSHIP_EDIT_ACTION = 'membership-list-edit';

export const membershipsColumnDefinitions: ColumnDefinition[] = [
  {
    displayName: 'X.LABEL.ORGANISATION',
    key: 'organizationName',
    sortable: false,
  },
  {
    displayName: 'X.LABEL.ROLE',
    key: 'roleName',
    sortable: false,
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<Membership>,
      config: {
        prefix: 'X.ROLE.',
      },
    },
  },
  {
    displayName: 'MEMBERSHIPS.LIST.STATE.HEADER',
    key: 'state',
    sortable: false,
    renderer: {
      component: MembershipStateRendererComponent as ColumnRendererComponent<Membership>,
      config: {
        mapping: {
          PENDING: '16px_c-info',
          ACCEPTED: '16px_check',
          EXPIRED: '16px_warning-sign',
          DECLINED: '16px_declined',
          INHERITED: '16px_pin-4',
        },
        postfixMapping: {
          PENDING: 'MEMBERSHIPS.LIST.STATE.PENDING',
          ACCEPTED: 'MEMBERSHIPS.LIST.STATE.ACCEPTED',
          EXPIRED: 'MEMBERSHIPS.LIST.STATE.EXPIRED',
          DECLINED: 'MEMBERSHIPS.LIST.STATE.DECLINED',
          INHERITED: 'MEMBERSHIPS.LIST.STATE.INHERITED',
        },
      } as MembershipStateRendererConfig,
    },
  },
  {
    displayName: '',
    key: 'edit',
    sortable: false,
    renderer: {
      component: EditRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        icon: '16px_edit',
        action: MEMBERSHIP_EDIT_ACTION,
      },
    },
  },
];
