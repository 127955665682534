<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header class="home-page-header" *ngIf="!isAppDetailRoute()" [withSidebar]="true" [hasBackArrow]="false">
      <div class="page-header-content" *ngIf="!isAboutGEARoute()">
        <geaui-tab-menu-v2 [tabItems]="tabItems"></geaui-tab-menu-v2>
        <div class="edit-dashboard-container" *ngIf="isDashboardRoute()">
          <geaui-link-v2 (click)="editDashboard()" type="secondary" preIcon="16px_pen"></geaui-link-v2>
        </div>
      </div>
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <router-outlet></router-outlet>
  </ng-container>
</geaui-page-content-layout>
