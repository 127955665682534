import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import {
  ApiErrorResponse,
  App,
  ErrorHandlerV2Service,
  LayoutService,
  PermissionKey,
  PermissionsState,
  SnackbarService,
} from '@gea/digital-ui-lib';
import { first, Observable } from 'rxjs';

import { AppAndIconService } from '../services/app-and-icon.service';
import { Store } from '@ngxs/store';
import { OrganizationService } from '@gea-id/shared';
import { Router } from '@angular/router';
import { Screenshot } from '../models/Screenshot.model';
import { CarouselResponsiveOptions } from 'primeng/carousel';

@Component({
  selector: 'gea-id-workspace-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.scss'],
})
export class AppDetailComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) appId = '';
  @ViewChild('appDetailDescription') descriptionRef!: ElementRef<HTMLElement>;

  loading = true;
  hasVerificationPermission = false;
  selectedApp?: string;
  appData?: App;
  verificationRequested = false;
  screenshotData: Screenshot[] | undefined;
  textExpanded = false;
  hasShowMoreButton = false;
  responsiveOptions: CarouselResponsiveOptions[] = [
    {
      breakpoint: '1919px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '1024px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(
    private appService: AppAndIconService,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackbarService: SnackbarService,
    public layout: LayoutService,
    public orgaService: OrganizationService,
    private store: Store,
    public router: Router
  ) {}

  get screenshotUrl(): string | undefined {
    return this.appData?.imagePath ? this.appData.imagePath.replace(/\/icons\/.*/, '/images/') : undefined;
  }

  ngOnInit(): void {
    // This is the official syntax of ngxs
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.store.select(PermissionsState.userPermissions).subscribe((permissions) => {
      this.hasVerificationPermission = permissions.includes(PermissionKey.REQUEST_VERIFICATION);
    });

    /* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */
    this.appService
      .getApp(this.appId)
      .pipe(first())
      .subscribe((app) => {
        this.loading = false;
        this.appData = app;
        this.initScreenshotData();
        this.setShowMore();
      });
    /* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */
  }

  ngAfterViewInit() {
    this.setShowMore();
  }

  private initScreenshotData() {
    this.screenshotData = [];
    if (!this.screenshotUrl) {
      return;
    }
    // statically set to 3 entries for now
    for (let i = 0; i < 3; i++) {
      this.screenshotData?.push({ id: i.toString(), loaded: true, url: this.screenshotUrl + 'screenshot-' + i + '.png' });
    }
  }

  onRequestVerification(): Observable<void> {
    this.verificationRequested = true;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const observable: Observable<void> = this.orgaService.requestOrganizationVerification();
    // handle error and success outside of ConfirmPrompt to avoid circular dependency issues
    observable.pipe(first()).subscribe({
      next: () => {
        this.snackbarService.add({
          summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          severity: 'success',
          detail: 'DASHBOARD.VERIFY.SUCCESS',
        });
      },
      error: (e: ApiErrorResponse) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.errorHandlerService.handleError(e);
      },
    });
    return observable;
  }

  routeAllApps() {
    void this.router.navigate(['all-apps']);
  }

  // for hiding image if path not available
  onImageError(imageId: number) {
    if (this.screenshotData && imageId) {
      this.screenshotData[imageId].loaded = false;
    }
  }

  routeToApp() {
    if (this.appData?.url) {
      location.href = this.appData.url;
    }
  }

  toggleShowMore() {
    this.textExpanded = !this.textExpanded;
  }

  setShowMore() {
    if (this.descriptionRef) {
      const descriptionElement = this.descriptionRef.nativeElement;
      const maxHeight = parseFloat(getComputedStyle(descriptionElement).lineHeight) * 3;
      const actualHeight = descriptionElement.scrollHeight;
      if (actualHeight > maxHeight) {
        this.hasShowMoreButton = true;
      }
    }
  }
}
